import BillsEntity from '../../shared/entities/BillsEntity';
import * as constants from '../actions';

export interface billInitialState {
  bill: BillsEntity | null;
  originalBill: BillsEntity | null;
}

const initialState: billInitialState = {
  bill: null,
  originalBill: null
};

export function billReducer(state: billInitialState = initialState, action: any): billInitialState {
  switch (action.type) {
    /* Token cases */
    case constants.GENERATE_BILL:
      return {
        ...state,
        bill: action.payload
      };
    case constants.RESET:
      return {
        ...state,
        bill: null,
        originalBill: null
      };
    case constants.ORIGINAL_BILL:
      return {
        ...state,
        originalBill: action.payload
      };

    default:
      return state;
  }
}
