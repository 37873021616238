
import React from 'react';
import './spinnerStyle.scss';
import { Spinner } from 'react-bootstrap';

class Spinnercomponet extends React.Component {
  render() {
    return (

      <div className="spinner-component">

        <Spinner animation="border" />

      </div>
    );
  }
}

export default Spinnercomponet;
