/**
 * This is the base for all our entities.
 * This is just for the inheritance of the
 * service-entity dependency.
 */
export default class BaseEntity {
  /**
   * This is a generic constructor
   * to basically assign some entity properties
   * from the constructor
   * @param initialValues
   */

  constructor(initialValues: any) {
    Object.assign(this, initialValues);
  }
}
