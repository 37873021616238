import BillsEntity from '../../shared/entities/BillsEntity';
import { RESET } from './AuthenticationActions';
export const GENERATE_BILL = '[BILL] GENERATE_BILL';
export const CLEAR_BILL = '[BILL] CLEAR_BILL';
export const ORIGINAL_BILL = '[BILL] ORIGINAL_BILL';

/**
 * this action is used to save in redux the bill
 * @param data
 */
export function generateBill(data: BillsEntity) {
  return {
    type: GENERATE_BILL,
    payload: data
  };
}

/**
 * this action is used to save in redux the bill
 */
export function clearBill() {
  return {
    type: RESET
  };
}
/**
 * this action is used to save original bill in redux the bill
 */
export function setOriginalBill(data: BillsEntity) {
  return {
    type: ORIGINAL_BILL,
    payload: data
  };
}
