import * as React from 'react';
import LoginForm from '../components/LoginForm';

export interface ILoginProps{}

const Login: React.FunctionComponent<ILoginProps> =
  (_props) => {
    return (
      <div className="login">
        <div className="login-card">
          <div className="container">
            <div className="row">
              <div className="col col-lg-5 pr-0 px-0 login-container-form">
                <div className="container-form">
                  <LoginForm />
                </div>
              </div>
              <div className="col col-lg-7 pl-0 d-none d-lg-block d-xl-block ">
                <div className="login-container-image">
                  {/* <div className="container">
                    <h2>Bienvenidos a</h2>
                    <p>Pagos UNPHU en linea</p>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
export default Login;
