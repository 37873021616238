
export enum currencyEnum {
  DOP = 1,
  USD = 2
}

export function currency(data: number| string, code:string = currencyEnum[currencyEnum.DOP]) {
  const symbol = new Intl.NumberFormat('es-DO', {
    style: 'currency',
    currency: code,
    currencyDisplay: 'symbol',
    useGrouping: true
  }).formatToParts(Number(data)).map(({ type, value }) => {
    switch (type) {
      case 'currency': return `${value}`;
      default : return value;
    }
  }).reduce((string, _part) => string);
  const number = new Intl.NumberFormat('en-US').format(Number(data));
  return `${symbol} ${number}`;
}
