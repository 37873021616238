import { Dispatch } from 'redux';
import Axios from 'axios';
import * as constants from './index';
import { CryptoService } from '../../services/crypto/CryptoService';
export const LOGIN = '[AUTENTICATION] LOGIN';
export const LOGIN_STOP = '[AUTENTICATION] LOGIN_STOP';
export const LOGIN_SUCCESS = '[AUTENTICATION] LOGIN_SUCCESS';
export const LOGIN_ERROR = '[AUTENTICATION] LOGIN_ERROR';
export const LOGOUT = '[AUTENTICATION] LOGOUT';
export const LOGOUT_SUCCESS = '[AUTENTICATION] LOGOUT_SUCCESS';
export const RESET = 'RESET';
export const VALIDATE_DATA = '[AUTENTICATION] VALIDATE_DATA';
const crypto = new CryptoService(process.env.REACT_APP_CRYPTO_KEY || '');

/**
 * this action is used to log in a user
 * @param username
 * @param password
 */
export function loginAction(username: string, password: string) {
  return async(dispatch: Dispatch) => {
    dispatch({ type: LOGIN });
    try {
      const data = crypto.encryptObject({
        username,
        password
      });

      await Axios.post(`${crypto.decrypt(process.env.REACT_APP_API_URL || '')}/login`, data)
        .catch((e) => {
          if (e.toString().indexOf('999') !== -1) {
            dispatch(constants.openModal({
              title: 'Mantenimiento en curso',
              description: 'Disculpe los inconvenientes.'
            }));
            dispatch({ type: LOGIN_STOP, error: e.data });
          } else {
            dispatch({ type: LOGIN_ERROR, error: e.data });
          }
        })
        .then((r) => {
          if (r) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: crypto.decryptObject(r.data.data)
            });
          }
        });
    } catch (e) {
      dispatch({ type: LOGIN_ERROR, error: e });
    }
  };
}

/**
 * this action is used to update verifiedData in redux
 */
export function validateDataAction() {
  return async(dispatch: Dispatch) => {
    dispatch({ type: VALIDATE_DATA });
  };
}

/**
 * this action is used to log out a user
 */
export function logoutAction() {
  return async(dispatch: Dispatch) => {
    dispatch({ type: LOGOUT });
    dispatch({ type: LOGOUT_SUCCESS });
    dispatch({ type: constants.CLEAR_BILL });
    dispatch({ type: constants.RESET });
    localStorage.removeItem('token');
  };
}
