import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationInitialState } from '../../store/reducers/AuthenticationReducer';
import { RouteProps } from 'react-router';
import { stateType } from '../../store';
import { connect, useSelector } from 'react-redux';
import { useEffect } from 'react';

export interface PrivateRouteProps extends RouteProps{}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> =
  (props) => {
    const {
      exact,
      path
    } = props;
    const authentication = useSelector<stateType, authenticationInitialState>(({ authenticationReducer }) => authenticationReducer);
    useEffect(() => {
      if (!localStorage.getItem('token') && window.location.pathname !== '/login') {
        window.location.href = '/login';
      }
    }, []);
    const isLogin = !!authentication.user;
    return (
      <Route
        exact = { exact }
        path = {path}
      >
        {
          !isLogin
            ? <Redirect to={{ pathname: '/login' }} />
            : props.children
        }
      </Route>
    );
  };
export function mapStateToProps(state: stateType) {
  return {
    authentication: state.authenticationReducer
  };
}

export default connect(mapStateToProps)(PrivateRoute);
