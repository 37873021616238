import React from 'react';
import { withFormsy } from 'formsy-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WrapperInstanceMethods } from 'formsy-react/dist/withFormsy';

export interface TextFieldFormsyProps extends WrapperInstanceMethods <any>{
  className?: string
  wrappedClass?: string
  placeholder?: string
  required?: string
}

const TextFieldFormsy: React.FunctionComponent<TextFieldFormsyProps | any> =
  (props) => {
    const errorMessage = props.errorMessages;
    const value = props.value || '';

    function changeValue(event: any) {
      props.setValue(event.currentTarget.value);
      if (props.onChange) {
        props.onChange(event);
      }
    }
    return (
      <div className="mb-4">

        <div className={props.wrappedClass}>
          {
            props.label &&
          <div id="icon-input" className="d-flex">
            {props.icon && <FontAwesomeIcon icon={props.icon} className="icons" />}
            <span className="ml-1">{props.label}{props.required && '*'}</span>
          </div>
          }

          <input
            className={props.className}
            placeholder={props.placeholder}
            type="text"
            name={props.name}
            onChange={changeValue}
            value={value}
          />

          <small className="form-text text-danger">{errorMessage}</small>
        </div>

      </div>

    );
  };

export default withFormsy(TextFieldFormsy);
