import * as React from 'react';
import PaymentCheckoutForm from '../components/PaymentCheckoutForm';
import FooterComponent from '../components/FooterComponent';
export interface IPaymentsProcessProps {
}

class PaymentsProcess extends React.Component<IPaymentsProcessProps> {
  public render() {
    return (
      <div className="payment-process">
        <PaymentCheckoutForm/>
        <FooterComponent/>
      </div>
    );
  }
}
export default PaymentsProcess;
