import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import TopBar from '../TopBar';
import { customRules } from '../../shared/utills/Validation';
import ModalMainAdvice from '../ModalMainAdvice/ModalMainAdvice';

export interface LayoutProps extends RouteComponentProps{}

const Layout: React.FunctionComponent<LayoutProps> =
  (props) => {
    // go to top always
    window.scrollTo(0, 0);
    // init custom rules
    customRules();
    return (
      <>
        <ModalMainAdvice/>
        {props.location.pathname !== '/login' && (<TopBar/>)}
        {props.children}
      </>
    );
  };

export default withRouter(Layout);
