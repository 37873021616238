import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import * as Actions from '../../store/actions';
import { stateType } from '../../store';
import { connect } from 'react-redux';
import { authenticationInitialState } from '../../store/reducers/AuthenticationReducer';

export interface ITopBarProps {
  authentication: authenticationInitialState
  logoutAction: () => void
}

const TopBar: React.FunctionComponent<ITopBarProps> =
  (props) => {
    const { authentication } = props;
    return (
      <div className="container-header">
        <Navbar>
          <Navbar.Brand>
            <Link to="/pagos">Pagos en línea</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
          {
            authentication.user &&
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                <FontAwesomeIcon icon={faSignOutAlt} />{' '}
                <a href="#?" onClick={() => props.logoutAction()}>Cerrar</a>
              </Navbar.Text>
            </Navbar.Collapse>
          }
        </Navbar>
      </div>
    );
  };

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    logoutAction: Actions.logoutAction
  }, dispatch);
};
const mapState2Props = (state: stateType) => {
  return {
    authentication: state.authenticationReducer
  };
};

export default withRouter(connect(mapState2Props, mapDispatchToProps)(TopBar));
