import * as React from 'react';
import { useEffect, useState } from 'react';
import FooterComponent from '../components/FooterComponent';
import PersonalInformation from '../components/PersonalInformation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import PayList from '../components/PayList';
import { Alert, Button, Form } from 'react-bootstrap';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { stateType } from '../store';
import BaseService from '../services/RestService/BaseService';
import UserEntity from '../shared/entities/UserEntity';
import BillsEntity from '../shared/entities/BillsEntity';
import { currency, currencyEnum } from '../shared/utills/currency';
import * as Actions from '../store/actions';
import { find, get, isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ModalAdvice, { ModalProps } from '../components/ModalAdvice/ModalAdvice';
import { ICreatePaymentPlanEntity } from '../shared/entities/PaymentPlanEntity';
import ModalForm from '../components/ModalForm/ModalForm';
import CurrencyEntity from '../shared/entities/CurrencyEntity';
import { useParams } from 'react-router-dom';
import { TokenService } from '../services/TokenService';
import { LOGIN_SUCCESS } from '../store/actions';
import { billInitialState } from '../store/reducers/BillReducer';
export interface IPaymentsProps extends RouteComponentProps {
  user: UserEntity | null;
  currencyData: CurrencyEntity;
  findBill: (query?: string) => Promise<void>;
  generateBill: (data: BillsEntity) => void;
  payBill: (data: object) => void;
  setOriginalBill: (data: BillsEntity) => void;
  getCurrency: (x: any) => void;
  logoutAction: () => void;
  paymentPlanError: object | any;
  deletePaymentPlan: (id: any) => Promise<void>;
  createPaymentPlan: (data: ICreatePaymentPlanEntity) => Promise<void>;
  loading: boolean
  error: any
}

const Payments: React.FunctionComponent<IPaymentsProps> =
  (props) => {
    const {
      user,
      findBill,
      getCurrency,
      currencyData,
      loading,
      paymentPlanError,
      generateBill,
      setOriginalBill
    } = props;
    const obligatePayNotDueAmount = [1, 54, 120, 45, 46, 47, 89, 48, 63, 21, 24, 28, 115, 116, 117, 19, 20, 17, 140, 126, 49, 50, 115, 66, 18];
    // const optionalPayNotDueAmount = [33, 141];
    const removeService = ['101', '102'];
    const enrolmentService = [76, 97];
    const reEnrolmentServiceToGroup = [6, 15, 100];
    const registrationServiceGroup = [32, 36, 97, 148];
    const listBill = useSelector<stateType, BillsEntity>(({ entityReducer }) => entityReducer.bill.find.data);
    const billReducer = useSelector<stateType, billInitialState>(({ billReducer }) => billReducer);
    const [data, setData] = useState<BillsEntity>(listBill);
    const [needRecalculate, setNeedRecalculate] = useState<boolean>(true);
    const [payAll, setPayAll] = useState<boolean>(false);
    const [applyPaymentPlan, setApplyPaymentPlan] = useState<boolean>(false);
    const [dataToPaymentPlan, setDataToPaymentPlan] = useState<ICreatePaymentPlanEntity>();
    const [onlyRead, setOnlyRead] = useState<boolean>(false);
    const [selectionCompleteAdvice, setSelectionCompleteAdvice] = useState<boolean>(false);
    const [modalConfig, setModalConfig] = useState<ModalProps>({
      isOpen: false,
      title: '',
      icons: 'faCheck',
      subTitle: '',
      description: '',
      error: true,
      onClose: () => {
      }
    });
    const { token } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
      const pathname = window.location.pathname.split('/')[1];
      token ? TokenService.decode(token, process.env.REACT_APP_SECRET || 'secret').then((data) => {
        const typeLink = pathname === 'admisiones' || pathname === 'pago-prueba';
        const preInscription = pathname === 'pre-inscripcion';

        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: {
              enclosure: undefined,
              career: undefined,
              names: data.user.person.names,
              verifiedData: true,
              typeLink,
              preInscription,
              cardId: data.user.person.cardId,
              email: data.user.person.email
            } as Partial<UserEntity>
          }
        });
        findBill(typeLink ? '?type=1' : '').then(() => getCurrency(1));
      }) : findBill().then(() => getCurrency(1));
      setOnlyRead(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // if (get(listBill, 'accountNumber') !== get(data, 'accountNumber')) {
      setOriginalBill(listBill);
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listBill]);

    const isAdmission = props.match.path.indexOf('admisiones') >= 0;
    useEffect(() => {
      if (listBill) {
        setData(listBill);
      }
    }, [listBill]);
    useEffect(() => {
      // if (billReducer.originalBill && user && user.typeLink && !(
      //   (find(billReducer.originalBill.serviceCharges, (service) => (service.service.id === 2))) ||
      //   (find(billReducer.originalBill.serviceCharges, (service) => (service.service.id === 1))) ||
      //   (find(billReducer.originalBill.serviceCharges, (service) => (service.service.id === 1))) ||
      //   (find(billReducer.originalBill.serviceCharges, (service) => (service.service.id === 155)))
      // )) {
      //   props.logoutAction();
      //   window.location.href = '/login';
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billReducer, user]);
    useEffect(() => {
      // if (billReducer.originalBill && user && user.preInscription && !(
      //   (find(billReducer.originalBill.serviceCharges, (service) => (service.service.id === 198)))
      // )) {
      //   props.logoutAction();
      //   window.location.href = '/login';
      // }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billReducer, user]);
    useEffect(() => {
      (listBill && get(listBill, 'paymentPlanId') === get(listBill, 'paymentPlan.id', 0)) ? setApplyPaymentPlan(true) : setApplyPaymentPlan(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listBill]);
    const onChangePaymentPlan = (e: any) => {
      if (e.target.checked) {
        const dataSend = {
          year: listBill.year,
          period: listBill.period
        };
        props.createPaymentPlan(hasScholarship ? { ...dataSend, scholarship: true } : dataSend).then(() => {
          // setApplyPaymentPlan(true);
          props.findBill();
        });
      } else {
        props.deletePaymentPlan(`${listBill.person}?period=${listBill.period}&year=${listBill.year}`).then(() => props.findBill()).then(() => {
          // setApplyPaymentPlan(true);
          props.findBill();
        });
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    };
    const handlerToPay = (indirectly?: boolean) => {
      if (selectionCompleteAdvice && !indirectly) {
        openModalAdviceWarning();
        setSelectionCompleteAdvice(false);
      } else {
        generateBill({
          ...data
        });
        props.history.push('/proceso-de-pago');
      }
    };
    const hasScholarship = !isEmpty(listBill && get(listBill, 'scholarships', []));

    const openModalAdviceWarning = () => {
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        title: '',
        icons: 'faExclamation',
        description: 'Antes de realizar el pago de matriculación por favor asegurarse que su selección de asignaturas está completa. Cargos adicionales podrían ser añadido a su cuenta en caso de que la cantidad de créditos seleccionados aumente luego del pago realizado. ',
        error: false,
        onClose: closeModal
      });
    };
    const openModalError = () => {
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        icons: 'faTimesCircle',
        title: 'Algo salio mal.',
        description: 'La factura no esta disponible en este momento',
        error: true,
        onClose: closeOnlyModal
      });
    };
    const openModalHaventPaymentPlan = () => {
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        icons: 'faExclamation',
        description: 'Plan de pago no disponible actualmente',
        error: false,
        onClose: closeOnlyModal
      });
    };
    const closeModal = () => {
      handlerToPay(true);
      setModalConfig({
        ...modalConfig,
        isOpen: false
      });
    };
    const closeOnlyModal = () => {
      setModalConfig({
        ...modalConfig,
        isOpen: false
      });
    };
    const total = data && data.totals.amountToBill;
    const disableToContinue = (data && Number(data.totals.amountToBill) <= 0 && data.serviceCharges.length <= 2) || !listBill;

    return (
      <div className="view-payments">
        <ModalAdvice {...modalConfig} />
        <ModalForm/>
        <PersonalInformation user={user} listBill={data}/>
        <hr className="hr"/>
        <div className="container">
          <Alert
            variant="danger"
            hidden={!((data && get(data, 'pendingPayment', false)))} //
          >
            Usted presenta un pago en proceso utilizando una tarjeta de crédito/débito pagando
            <small className="text-danger"> {data && get(data, 'pendingPayment', '') && currency(get(data, 'pendingPayment.MontoPagado', ''), currencyEnum[data.suggestedAccount.currencyType.id])} </small> y el numero de aprobación:
            <small className="text-danger"> #{data && get(data, 'pendingPayment.NumeroAprobacion', '')} </small> {/* // */}
            Estaremos trabajando en este caso, para mas información contacto <span className="text-danger">mesadeayuda@unphu.edu.do</span>
          </Alert>
          <Alert
            variant="danger"
            hidden={(!get(data, 'lockToPay', false))} //
          >
           Estudiante inactivo

          </Alert>
          <div className="row">

            <div className="col">
              <div className="container-text">
                <div className="pay-text d-flex align-items-center">
                  <FontAwesomeIcon icon={faMoneyBill} size="1x" style={{ color: '#616363' }}/>
                  <span className="total-amount">Detalle de cuenta</span>
                </div>
                <div className="select all">
                  {!isAdmission && <Form.Group>
                    <Form.Check type="checkbox"
                      disabled={loading}
                      defaultChecked={payAll}
                      onClick={() => {
                        if (!payAll) {
                          const pathname = window.location.pathname.split('/')[1];
                          token ? TokenService.decode(token, process.env.REACT_APP_SECRET || 'secret').then((data) => {
                            const typeLink = pathname === 'admisiones' || pathname === 'pago-prueba';
                            dispatch({
                              type: LOGIN_SUCCESS,
                              payload: {
                                user: {
                                  enclosure: undefined,
                                  career: undefined,
                                  names: data.user.person.names,
                                  verifiedData: true,
                                  typeLink,
                                  cardId: data.user.person.cardId,
                                  email: data.user.person.email
                                } as Partial<UserEntity>
                              }
                            });
                            findBill(typeLink ? '?type=1&payAll=true' : '').then(() => getCurrency(1));
                          }) : findBill('?payAll=true').then(() => getCurrency(1));
                          setOnlyRead(false);
                          setPayAll(!payAll);
                        } else {
                          const pathname = window.location.pathname.split('/')[1];
                          token ? TokenService.decode(token, process.env.REACT_APP_SECRET || 'secret').then((data) => {
                            const typeLink = pathname === 'admisiones' || pathname === 'pago-prueba';
                            dispatch({
                              type: LOGIN_SUCCESS,
                              payload: {
                                user: {
                                  enclosure: undefined,
                                  career: undefined,
                                  names: data.user.person.names,
                                  verifiedData: true,
                                  typeLink,
                                  cardId: data.user.person.cardId,
                                  email: data.user.person.email
                                } as Partial<UserEntity>
                              }
                            });
                            findBill(typeLink ? '?type=1' : '').then(() => getCurrency(1));
                          }) : findBill().then(() => getCurrency(1));
                          setOnlyRead(false);
                          setPayAll(!payAll);
                        }
                      }}
                      label="Pago total"
                      id="check-checkbox"
                    />
                  </Form.Group>}
                  {data && data.paymentPlan &&
                  <Form.Check
                    disabled={loading}
                    defaultChecked={applyPaymentPlan}
                    onClick={onChangePaymentPlan}
                    type="checkbox"
                    id='check-applyPaymentPlan'
                    label={'Pagar matriculación completa'}/>
                  }
                </div>
              </div>

              {/* componet PayList */}
              <PayList
                enrolmentService={enrolmentService}
                payCurrent={payAll}
                data={data}
                onlyRead={onlyRead}
                loading={loading}/>
              <span className="text-muted">
                &#9679; <small>Para consultas sobre montos o servicios a pagar: <b>cobros@unphu.edu.do</b></small>
                <br/>
                &#9679; <small>De <b>11:25 p.m.</b> a <b>12:30 a.m.</b> el portal estara inhabilitado para Pagos al instante</small>
              </span>

              <div className="makePayment mt-3">
                <span>Total: {(!isNaN(Number(total)) && currency(total, currencyEnum[data.suggestedAccount.currencyType.id])) || 0}</span>
                <Button
                  disabled={disableToContinue || (data && data.lockToPay) || loading || !!((data && false)) || !isEmpty(get(data, 'pendingPayment', true))}
                  onClick={() => handlerToPay()}
                  variant="primary"
                >Continuar</Button>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent/>
      </div>
    );
  };

const billService = new BaseService('bill');
const paymentPlanService = new BaseService('paymentPlan');
const currencyService = new BaseService('currency');
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    findBill: billService.find as any,
    payBill: billService.create,
    createPaymentPlan: paymentPlanService.create as any,
    deletePaymentPlan: paymentPlanService.remove as any,
    generateBill: Actions.generateBill,
    logoutAction: Actions.logoutAction,
    setOriginalBill: Actions.setOriginalBill,
    getCurrency: currencyService.get
  }, dispatch);
};
const mapState2Props = (state: stateType) => {
  return {
    user: state.authenticationReducer.user,
    listBill: state.entityReducer.bill.find.data,
    paymentPlanError: state.entityReducer.paymentPlan.create.error,
    currencyData: state.entityReducer.currency.get.data,
    error: state.entityReducer.bill.find.error,
    loading: state.entityReducer.bill.find.loading ||
      state.entityReducer.paymentPlan.create.loading ||
      state.entityReducer.paymentPlan.remove.loading
  };
};

export default withRouter(connect(mapState2Props, mapDispatchToProps)(Payments));
