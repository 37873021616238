import * as constants from '../actions';

export interface commonInitialState {
  isOpen: boolean,
  title: string,
  subTitle: string,
  description: string | any,
  error: boolean,
  icons: 'faTimesCircle'| 'faCheck' | 'faExclamation' | 'faWrench';
}

const initialState: commonInitialState = {
  isOpen: false,
  title: 'alerta',
  subTitle: '',
  description: '',
  error: false,
  icons: 'faWrench'
};

export function commonReducer(state: commonInitialState = initialState, action: any): commonInitialState {
  switch (action.type) {
    /* Token cases */
    case constants.OPEN_MODAL_ADVICE:
      return {
        ...state,
        ...action.payload,
        isOpen: true
      };
    case constants.CLOSE_MODAL_ADVICE:
      return {
        ...state,
        isOpen: false
      };

    default:
      return state;
  }
}
