import { commonInitialState } from '../reducers/CommonReducer';

export const OPEN_MODAL_ADVICE = '[COMMON] OPEN MODAL ADVICE';
export const CLOSE_MODAL_ADVICE = '[COMMON] CLOSE_MODAL_ADVICE';

/**
 * this action is used to open modal at redux
 */
export function openModal(config:commonInitialState|{} = {}) {
  return {
    type: OPEN_MODAL_ADVICE,
    payload: config
  };
}

/**
 * this action is used to close modal at redux
 */
export function closeModal() {
  return {
    type: CLOSE_MODAL_ADVICE
  };
}
