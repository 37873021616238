import React from 'react';
import { withFormsy } from 'formsy-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import { WrapperInstanceMethods } from 'formsy-react/dist/withFormsy';
export interface TextFieldFormsyProps extends WrapperInstanceMethods <any>{
  className?: string
  wrappedClass?: string
  placeholder?: string
  autocomplete?: string
  required?: string
  disabled?: boolean
  format?: string
  mask?: string
}

const NumberFormatFormsy: React.FunctionComponent<TextFieldFormsyProps | any> =
  (props) => {
    const errorMessage = props.errorMessages;
    const value = props.value || '';

    function changeValue(event: any) {
      props.setValue(event.currentTarget.value);
      if (props.onChange) {
        props.onChange(event);
      }
    }
    return (
      <div className={props.wrappedClass}>
        <div id="icon-input" className="d-flex">
          {props.icon && <FontAwesomeIcon icon={props.icon} className="icons" />}
          <span className="ml-1" >{props.label}{props.required && '*'}</span>
        </div>
        <NumberFormat
          className={`${props.className} ${errorMessage.length > 0 && 'border-danger border-2'}`}
          placeholder={props.placeholder}
          type="text"
          name="cardname"
          disabled={props.disabled}
          autoComplete={props.autoComplete}
          onChange={changeValue}
          value={value}
          format={props.format}
          mask={props.mask}
        />
        <small className="form-text text-danger">{errorMessage}</small>
      </div>
    );
  };

export default withFormsy(NumberFormatFormsy);
