import { combineReducers } from 'redux';
import { entityReducer } from './entityReducer';
import { authenticationReducer } from './AuthenticationReducer';
import { billReducer } from './BillReducer';
import { commonReducer } from './CommonReducer';
const createReducer = () =>
  combineReducers({
    entityReducer,
    billReducer,
    authenticationReducer,
    commonReducer
  });

export default createReducer;
