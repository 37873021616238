import UserEntity from '../../shared/entities/UserEntity';
import * as constants from '../actions';

export interface authenticationInitialState {
  user: UserEntity | any;
  expiresIn: number | null;
  token: string | null;
  refreshToken: string | null;
  loading: boolean;
  error: boolean;
}

const initialState: authenticationInitialState = {
  user: null,
  expiresIn: null,
  token: null,
  refreshToken: null,
  loading: false,
  error: false
};

export function authenticationReducer(state: authenticationInitialState = initialState, action: any): authenticationInitialState {
  switch (action.type) {
    /* Token cases */
    case constants.LOGIN:
      return {
        ...state,
        loading: true
      };
    case constants.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false
      };

    case constants.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    case constants.LOGIN_STOP:
      return {
        ...state,
        loading: false
      };
    /* Token cases */
    case constants.LOGOUT:
      return {
        ...state,
        loading: true
      };
    case constants.LOGOUT_SUCCESS:
      return {
        ...initialState
      };
    case constants.VALIDATE_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          verifiedData: true
        }
      };

    default:
      return state;
  }
}
