import React from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import './App.scss';
import Layout from './components/layout/Layout';
import AllRouters from './components/AllRouters/AllRouters';

const App: React.FunctionComponent = () => {
  return (
    <Router>
      <Layout>
        <AllRouters/>
      </Layout>
    </Router>
  );
};

export default App;
