import UserEntity from './UserEntity';

const Entities = {
  user: UserEntity,
  bill: { path: 'cash-process' },
  rnc: { path: 'rnc-service' },
  paymentPlan: { path: 'payment-plan' },
  currency: { path: 'currency' },
  personalInformation: { path: 'personal-information' }
};
export default Entities;
