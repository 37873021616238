import BaseEntity from './BaseEntity';

export default class UserEntity extends BaseEntity {
  id = '';
  names = '';
  email = '';
  username = '';
  enrollment = '';
  enclosure = '';
  cardId: Number = 0;
  scholarship = '';
  phone = '';
  mobile = '';
  preInscription: boolean = false;
  personalEmail = '';
  verifiedData: boolean | null = null;
  career = '';
  typeLink : boolean = false;
}
