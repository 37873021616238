import React from 'react';
import './ModalMainAdvice.scss';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimesCircle, faExclamation, faWrench } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { stateType } from '../../store';
import * as Actions from '../../store/actions';
import { commonInitialState } from '../../store/reducers/CommonReducer';

export interface ModalMainAdviceProps{}

const ModalMainAdvice: React.FunctionComponent<ModalMainAdviceProps> =
  (props) => {
    const config = useSelector<stateType, commonInitialState>(({ commonReducer }) => commonReducer);
    const dispatch = useDispatch();
    const icon = () => {
      switch (config.icons) {
        case 'faCheck':
          return faCheck;
        case 'faExclamation':
          return faExclamation;
        case 'faTimesCircle':
          return faTimesCircle;
        case 'faWrench':
          return faWrench;
        default:
          return faExclamation;
      }
    };
    const isAdvice = config && (config.icons === 'faExclamation' || 'faWrench');
    return config && (
      <div className="Modal-Container">

        <Modal show={config.isOpen} onHide={() => undefined}>
          <Modal.Body>
            <div className={isAdvice ? 'container-alert-warning' : (!config.error ? 'container-alert-valid' : 'container-alert')}>
              <FontAwesomeIcon icon={icon()} className="icon-modal" />
              <h5>{config.title}</h5>
              <h2>{config.subTitle}</h2>
            </div>

            <div className="footer-alert">
              <p>
                {config.description}
              </p>
              {(config.error || isAdvice) && <p className="text-muted font-weight-light"> Para preguntas escribir <a href="https://asistencia.unphu.edu.do">asistencia.unphu.edu.do</a></p>}
              <Button className="btn-gmail " onClick={() => dispatch(Actions.closeModal())} variant="outline-primary">Cerrar</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

export default ModalMainAdvice;
