import { addValidationRule } from 'formsy-react';

export const customRules = () => {
  /**
   * minDate Rule to Formsy
   */
  addValidationRule('isDateGreaterThanNow', function(values: object, value:string, _ruleValue: string) {
    const now = new Date();
    if (value) {
      const [incomeMount, incomeYear] = value.split('/').map(i => Number(i));
      const year = Number(now.getFullYear().toString().substr(-2));
      const mount = now.getMonth() + 1;
      if (Number(incomeYear) === year) {
        return mount < Number(incomeMount) && (Number(incomeMount) <= 12);
      }

      return year <= Number(incomeYear) && (Number(incomeMount) <= 12);
    }
    return true;
  });
  /**
   * minDate Rule to Formsy
   */
  addValidationRule('isCardNumber', function(values: object, value:string, _ruleValue: string) {
    if (value) {
      const incomeValue = value.replace(/ /g, '');
      return incomeValue.length === 16;
    }
    return true;
  });
  /**
   * consecutiveNumber Rule to Formsy
   */
  addValidationRule('consecutiveNumber', function(values: object, value:string, _ruleValue: string) {
    const incomeValue = value.replace(/[-()\s]/g, '');
    return !(/(.)\1\1\1\1/.test(incomeValue)) && !(/(0)\1\1\1/.test(incomeValue));
  });
  /**
   * consecutiveNumber Rule to Formsy
   */
  addValidationRule('noEmailDomain', function(values: object, value:string, ruleValue: string[]) {
    const domain = value.split('@').pop() || '';
    return ruleValue.indexOf(domain) === -1;
  });
  /**
   * isNumberPhone Rule to Formsy
   */
  addValidationRule('isNumberPhone', function(values: object, value:string, _ruleValue: string) {
    if (value) {
      const incomeValue = value.replace(/[-()\s]/g, '');
      return incomeValue.length === 10;
    }
    return true;
  });
};
