import React from 'react';
import { withFormsy } from 'formsy-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WrapperInstanceMethods } from 'formsy-react/dist/withFormsy';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
export interface PasswordFieldFormsyProps extends WrapperInstanceMethods <any>{
  className?: string
  wrappedClass?: string
  placeholder?: string
  required?: string
  isUnlockedPassword?: boolean
  setIsUnlockedPassword?: (value: boolean)=> void
}

const PasswordFieldFormsy: React.FunctionComponent<PasswordFieldFormsyProps | any> =
  (props) => {
    const errorMessage = props.errorMessages;
    const value = props.value || '';

    function changeValue(event: any) {
      props.setValue(event.currentTarget.value);
      if (props.onChange) {
        props.onChange(event);
      }
    }
    const {
      isUnlockedPassword,
      setIsUnlockedPassword
    } = props;
    return (
      <div className="inner-addon left-addon input-container">
        <input
          className={`${props.className} password-control`}
          type={isUnlockedPassword ? 'text' : 'password'}
          onChange={changeValue}
          value={value}
          placeholder={props.placeholder}
        />
        <span
          className="password-control-button"
          onClick={() => setIsUnlockedPassword(!isUnlockedPassword)}
        >
          <FontAwesomeIcon icon={isUnlockedPassword ? faEyeSlash : faEye} />
        </span>
        <span className="login-forgot-password-text">
          <span>
            {' '}
            <a href="https://cp.unphu.edu.do/">
            ¿Olvidaste tu contraseña?
            </a>
          </span>
        </span>
        <small className="form-text text-danger">{errorMessage}</small>
      </div>

    );
  };

export default withFormsy(PasswordFieldFormsy);
