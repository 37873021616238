
export function capitalize(sentence?: string | null, notCapitalizeFor?: string[], addDefaultPreps?:boolean) {
  if (sentence) {
    sentence = ' ' + sentence; // for cheking with \s operator in reges is needed an space this operator check all latin caracters
    const defaultPreps: any[] = ['Para', 'En', 'Por', 'Y'];
    const allExceptions: any[] | undefined = (addDefaultPreps) ? defaultPreps.concat(notCapitalizeFor) : notCapitalizeFor;
    const excepts : string = (allExceptions) ? allExceptions.join('|') : '';
    const regPreps: RegExp = RegExp(excepts, 'ig');

    sentence = sentence.toLowerCase().replace(/\s\w/g, (l: string) => l.toUpperCase())
    // removing the capitalize for exceptions received
      .replace(regPreps, (except) => except.toLowerCase())
    // checking for all roman numbers in the sentence received
      .replace(/\b[A-Z]+/ig, (l:string) =>
        /^(?=[MDCLXVI])M*(C[MD]|D?C*)(X[CL]|L?X*)(I[XV]|V?I*)$/ig.test(l)
          ? l.toUpperCase()
          : l);

    return sentence;
  } else { return ''; }
}
// for replacing some specific word in a text by the send in replaceby
export const replaceAt = (str:string, index: number, replaceBy:string | number) => {
  return str.substr(0, index) + replaceBy + str.substr(index + replaceBy.toString().length);
};
