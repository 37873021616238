
import React from 'react';
import './style.scss';

class FooterComponent extends React.Component {
  render() {
    return (
      <div className="footer-container">
        <div className="text-info">
          <span>UNPHU | Pagos en línea</span>
        </div>
      </div>
    );
  }
}
export default FooterComponent;
