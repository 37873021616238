import * as React from 'react';
import PaymentVector from '../assets/payment-vector.svg';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// import { connect } from 'react-redux'

export interface IErrorPageProps {}

class ErrorPage extends React.Component<IErrorPageProps> {
  public render() {
    return (
      <div className="container-404">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="text">
              <h3>ERROR</h3>
              <h1>404</h1>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Molestias repellat voluptate, quod illum maiores labore earum
                eaque aperiam, veritatis adipisicing elit. Molestias repellat
                voluptate, quod illum maiores labore earum eaque aperiam,
                veritatis
              </p>
              <Link to="/pagos">
                <Button variant="outline-primary">Ir al inicio</Button>
              </Link>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="container-vector">
              <img src={PaymentVector} alt="Pagos Unphu" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
// export default connect(mapState2Props)(ErrorPage);
