import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faIdCard, faSchool, faEnvelope, faMoneyBill, faGraduationCap } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import UserEntity from '../../shared/entities/UserEntity';
import { capitalize } from '../../shared/utills/StringsHelper';
import { currency, currencyEnum } from '../../shared/utills/currency';
import BillsEntity from '../../shared/entities/BillsEntity';

export interface IPersonalInformationProps {
  user: UserEntity | null;
  listBill: BillsEntity;
}

class PersonalInformation extends React.Component<IPersonalInformationProps> {
  public render() {
    const { user, listBill } = this.props;
    const u = user;
    return (
      <div className="personal-information">
        <div className="container">
          <div className="row">
            <div className="col col-lg-12">
            </div>

            {/* information user */}
            {/* <div className="col col-lg-10"> */}
            <div className="col col-lg-12 col-sm-12 col-md-12 col-12 name personal-information-container">
              <FontAwesomeIcon icon={faUser} className="icon" />
              {capitalize(u?.names)}
              {/* city */}
              {u?.enclosure &&
              <div className="col col-12 ">
                <p className="enrollment">
            Matricula: {u?.enrollment}
                </p>
              </div>}
            </div>

            {/* city */}
            {u?.career && <div className="col col-lg-12 col-12 career-section personal-information-container">
              <FontAwesomeIcon icon={faGraduationCap} className="icon"/>
              { u?.career.split(' / ').pop()}  <small>{u?.career.split(' / ').shift()}</small>
            </div>}

            {/* city */}
            {u?.enclosure && <div className="col col-lg-2 col-sm-6 col-md-6 col-12 personal-information-container">
              <FontAwesomeIcon icon={faSchool} className="icon" />
              { u?.enclosure}</div>}

            {/* cedula */}
            <div className="col col-lg-2 col-sm-6 col-md-6 col-12 personal-information-container">
              <FontAwesomeIcon icon={faIdCard} className="icon" />
              { u?.cardId}</div>

            {/* email */}
            <div className="col col-lg-3 col-sm-6 col-md-6 col-12 personal-information-container">
              <FontAwesomeIcon icon={faEnvelope} className="icon" />
              { u?.email}</div>

            {/* saldo a favor */}
            { !u?.typeLink && <div className="col col-lg-3 col-sm-6 col-md-6 col-12 personal-information-container">
              <FontAwesomeIcon icon={faMoneyBill} className="icon" />
              <b>Saldo a favor:</b> { (listBill && currency(listBill.balances.amountBalanceFavor, currencyEnum[listBill.suggestedAccount.currencyType.id])) || 0}</div>
            }
          </div>
        </div>
      </div>
    );
  }
}
export default PersonalInformation;
