import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from '../../pages/Login';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import Payments from '../../pages/Payments';
import PaymentsProcess from '../../pages/PaymentsProcess';
import ErrorPage from '../../pages/404';

const AllRouters: React.FunctionComponent = () => {
  return (
    <div>
      <Switch>
        <Route path="/login" exact={true} strict={true} component={Login} />
        <PrivateRoute
          exact
          path="/">
          <Login />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/pagos">
          <Payments />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/proceso-de-pago">
          <PaymentsProcess />
        </PrivateRoute>
        <PrivateRoute
          exact
          path="/proceso-de-pago">
          <PaymentsProcess />
        </PrivateRoute>

        <Route path="/pago-prueba/:token" component={Payments}/>
        <Route path="/admisiones/:token" component={Payments}/>
        <Route path="/inscripcion/:token" component={Payments}/>
        <Route path="/pre-inscripcion/:token" component={Payments}/>
        <Route component={ErrorPage}/>

      </Switch>
    </div>
  );
};

export default AllRouters;
