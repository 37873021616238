import { Dispatch } from 'redux';
import * as Actions from '../../../src/store/actions';
import Entities from '../../shared/entities/Entities';
import RestService from './RestService';
import { get } from 'lodash';

/**
 * This services will be extended from all the entities to
 * inherit the methods for doing a CRUD to the RestService and
 * dispatch the result through Redux.
 */
export default class BaseService {
  // Name to complete the route to fetch
  modelName = 'undefined_model_name';
  restService: RestService;
  /**
   *
   * @param entity { string }
   */
  constructor(entity: keyof typeof Entities) {
    this.modelName = entity.replace('Entity', '');
    this.restService = new RestService(entity);
    this.bindMethods();
  }

  /**
   * Find an entity and dispatch the result\
   * @param query {object | undefined}
   */
  find(query?: string) {
    return async(dispatch: Dispatch) => {
      dispatch({ type: `${this.modelName}_FIND_LOADING` });
      try {
        const payload = await this.restService.find(query);
        dispatch({ payload, type: `${this.modelName}_FIND_SUCCESS` });
      } catch (error) {
        this.handleError(dispatch, error, `${this.modelName}_FIND_ERROR`);
      }
    };
  }

  /**
   * Get an entity by person and dispatch the result
   * @param {number} id
   */
  get(id: number) {
    return async(dispatch:Dispatch) => {
      dispatch({ type: `${this.modelName}_GET_LOADING` });
      try {
        const payload = await this.restService.get(id);
        dispatch({ payload, type: `${this.modelName}_GET_SUCCESS` });
      } catch (error) {
        this.handleError(dispatch, error, `${this.modelName}_GET_ERROR`);
      }
    };
  }

  /**
   * Create an entity and dispatch when its done.
   * @param {Partial} data
   */
  create(data: object) {
    return async(dispatch: Dispatch) => {
      dispatch({ type: `${this.modelName}_CREATE_LOADING` });
      try {
        const payload = await this.restService.create(data);
        dispatch({ payload, type: `${this.modelName}_CREATE_SUCCESS` });
      } catch (error) {
        this.handleError(dispatch, error, `${this.modelName}_CREATE_ERROR`);
      }
    };
  }

  /**
   * Update an entity and dispatch when its done.
   * @param {number} id
   * @param {Object} data
   */
  update(id: number, data: object) {
    return async(dispatch: Dispatch) => {
      dispatch({ type: `${this.modelName}_UPDATE_LOADING` });
      try {
        const payload = await this.restService.update(id, data);
        dispatch({ payload, type: `${this.modelName}_UPDATE_SUCCESS` });
      } catch (error) {
        this.handleError(dispatch, error, `${this.modelName}_UPDATE_ERROR`);
      }
    };
  }

  /**
   * Remove an entity by person and dispatch when its done.
   * @param {number} id
   */
  remove(id: number) {
    return async(dispatch: Dispatch) => {
      dispatch({ type: `${this.modelName}_REMOVE_LOADING` });
      try {
        const payload = await this.restService.remove(id);

        dispatch({ payload, type: `${this.modelName}_REMOVE_SUCCESS` });
      } catch (error) {
        this.handleError(dispatch, error, `${this.modelName}_REMOVE_ERROR`);
      }
    };
  }

  handleError(dispatch: Dispatch, error: any, type: string) {
    if (error.toString().indexOf('999') !== -1) {
      dispatch(Actions.openModal({
        title: 'Mantenimiento en curso',
        description: 'Disculpe los inconvenientes'
      }));
      Actions.logoutAction()(dispatch);
    } else if (error.toString().indexOf('401') !== -1) {
      Actions.logoutAction()(dispatch);
    } else if (get(error, 'request.response')) {
      throw dispatch({ payload: JSON.parse(error.request.response), type });
    } else {
      throw dispatch({ payload: error, type });
    }
  }

   bindMethods = () => {
     this.find = this.find.bind(this);
     this.create = this.create.bind(this);
     this.get = this.get.bind(this);
     this.remove = this.remove.bind(this);
     this.update = this.update.bind(this);
   }
}
