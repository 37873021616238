import Axios from 'axios';
import Entities from '../../shared/entities/Entities';
import { cloneDeep, get } from 'lodash';
import { CryptoService } from '../crypto/CryptoService';
export default class RestService {
  protected config: any;
  protected crypto: CryptoService = new CryptoService(process.env.REACT_APP_CRYPTO_KEY || '');

  /**
   * Get the service urls
   * @param entity {string}
   */
  constructor(protected entity: string) {
    Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  /**
   * Get the service urls
   * @returns {string}
   */
  getServiceUrl() {
    const decryptUrl = this.crypto.decrypt(process.env.REACT_APP_API_URL || '');
    const context = get(Entities, `${this.entity}.context`);
    const path = get(Entities, `${this.entity}.path`);
    const modelName = this.entity.toLowerCase().replace('entity', '');
    if (path) {
      return `${decryptUrl}/${path}`;
    }
    return context
      ? `${decryptUrl}/${context}/${modelName}`
      : `${decryptUrl}/${modelName}`;
  }

  setToken() {
    Axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }

  /**
   * Method used to fetch a collection of entities
   * @param query
   */
  async find(query = '') {
    this.setToken();
    const response = await Axios.get(`${this.getServiceUrl()}${query ? `/${query}` : ''}`);
    if (response.status === 200) {
      return this.crypto.decryptObject(response.data.data);
    }
    return this.errorMessage(`find: ${query}`, response);
  }

  /**
   * Method used to fetch one entity by its person
   * @param {number | string} id
   * @returns {Promise}
   */
  async get(id: number) {
    const response = await Axios.get(`${this.getServiceUrl()}${id ? `/${id}` : ''}`);
    if (response.status === 200) {
      return this.crypto.decryptObject(response.data.data);
    }
    return this.errorMessage(`get: ${id}`, response);
  }

  /**
   * Method used to create an entity based on data
   * @param {Partial} data
   * @returns {Promise}
   */
  async create(data: any) {
    const dataCrypt = this.crypto.encryptObject(cloneDeep(data));
    const response = await Axios.post(this.getServiceUrl(), dataCrypt);
    if (response.status === 200 || response.status === 200) {
      return this.crypto.decryptObject(response.data.data);
    }
    return this.errorMessage(`create: ${data}`, response);
  }

  /**
   * Method used to update an entity based on data to the given person
   * @param {number} id
   * @param {Partial} data
   * @returns {Promise}
   */
  async update(id: number, data: any) {
    const dataCrypt = this.crypto.encryptObject(cloneDeep(data));
    const response = await Axios.put(`${this.getServiceUrl()}/${id !== undefined ? id : ''}`, dataCrypt);
    if (response.status === 200) {
      return this.crypto.decryptObject(response.data.data);
    }
    return this.errorMessage(`update: ${id} => ${data}`, response);
  }

  /**
   * Method used to delete an entity given its person
   * @param {number} id
   * @returns {Promise}
   */
  async remove(id: number) {
    const response = await Axios.delete(`${this.getServiceUrl()}${id ? `/${id}` : ''} `);
    if (response.status === 200) {
      return this.crypto.decryptObject(response.data.data);
    }
    return this.errorMessage(`delete: ${id}`, response);
  }

  /**
   * Return an error message from the method that threw the exception.
   * @param {string} methodName
   * @param response
   * @returns {Error}
   */
  errorMessage(methodName: string, response: any) {
    return new Error(
      `An error occurred while calling [${JSON.stringify(methodName)} ] on ${this.entity} service.\nResponse: ${JSON.stringify(response)}`
    );
  }
}
