import { set } from 'lodash';
import Entities from '../../../shared/entities/Entities';
import * as constants from '../../actions';

type methods = 'get' | 'find' | 'create' | 'update' | 'remove';
type entityTypes = keyof typeof Entities;

export type initEntityReducer = { [N in entityTypes]: {
    [N in methods]: {
      loading: boolean;
      loaded: boolean;
      error: undefined | object;
      data: undefined | any;
    }
} };

function buildIniState(entities: typeof Entities) {
  const initialState = {} as initEntityReducer;

  for (const entity in entities) {
    set(initialState, `${entity.substring(0, 1).toLowerCase() + entity.substring(1).replace('Entity', '')}`, {
      get: {
        loading: false,
        loaded: true,
        error: undefined,
        data: undefined
      },
      find: {
        loading: false,
        loaded: true,
        error: undefined,
        data: undefined
      },
      create: {
        loading: false,
        loaded: true,
        error: undefined,
        data: undefined
      },
      update: {
        loading: false,
        loaded: true,
        error: undefined,
        data: undefined
      },
      remove: {
        loading: false,
        loaded: true,
        error: undefined,
        data: undefined
      }
    });
  }

  return initialState as initEntityReducer;
}

export const entityReducer = (state: initEntityReducer = buildIniState(Entities), action: any) => {
  const actionTypeSplit = (action.type).split('_');
  const model: keyof typeof Entities = actionTypeSplit.shift() as entityTypes;
  let method: methods = actionTypeSplit.shift();
  method = method && method.toLowerCase() as methods;
  const type: any = actionTypeSplit.pop();
  if (type === 'ERROR') {
    return {
      ...state,
      [model]: {
        ...state[model],
        [method.toString().toLowerCase()]: { // get || create ....
          ...state[model][method],
          [type.toLowerCase()]: action.payload,
          loading: false
        }
      }
    };
  }
  if (type === 'SUCCESS') {
    return {
      ...state,
      [model]: {
        ...state[model],
        [method.toString().toLowerCase()]: {
          ...state[model][method],
          data: action.payload,
          loading: false

        }
      }
    };
  }
  if (type === 'LOADING') {
    return {
      ...state,
      [model]: {
        ...state[model],
        [method.toString().toLowerCase()]: {
          ...state[model][method],
          loading: true,
          error: undefined,
          data: undefined

        }
      }
    };
  }
  if (action.type === constants.RESET) {
    return {
      ...buildIniState(Entities)
    };
  }

  return {
    ...state
  };
};

export default entityReducer;
