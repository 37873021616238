import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { initEntityReducer } from './reducers/entityReducer/entity.reducer';
import createReducer from './reducers';
import { authenticationInitialState } from './reducers/AuthenticationReducer';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { billInitialState } from './reducers/BillReducer';
import { commonInitialState } from './reducers/CommonReducer';
// Declaring the variable for Redux Dev Tools extension.
declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
    }
}

// The compose enhancer.
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

export type stateType = {
    entityReducer: initEntityReducer,
    billReducer: billInitialState,
    authenticationReducer: authenticationInitialState,
    commonReducer: commonInitialState
}
// Persist config.
const persistConfig = {
  storage,
  key: 'app'
};

const persistedReducer = persistReducer(persistConfig, createReducer());

const store = createStore<any, any, any, any>(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
const persistor = persistStore(store);

export { persistor, store };
