import React, { useEffect, useRef, useState } from 'react';
import './ModalForm.scss';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { faMobile, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Formsy from 'formsy-react';
import NumberFormatFormsy from '../formsy/NumberFormatFormsy';
import TextFieldFormsy from '../formsy/TextFieldFormsy';
import UserEntity from '../../shared/entities/UserEntity';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { stateType } from '../../store';
import BaseService from '../../services/RestService/BaseService';
import PersonalInformationEntity from '../../shared/entities/PersonalInformationEntity';
import * as Actions from '../../store/actions';

export interface ModalFormProps{
  user: UserEntity | null,
  updatePersonalInformation: (data: object)=> Promise<void>
  validateDataAction: ()=> void
  error: object| undefined
  loading: boolean
}
const ModalForm: React.FunctionComponent<ModalFormProps> =
  (props) => {
    const { user, updatePersonalInformation, validateDataAction, loading } = props;
    const [isFormValid, setIsFormValid] = useState(false);
    const formRef: any = useRef(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    useEffect(() => {
      if (formRef.error && (formRef.error.name || formRef.error.code || formRef.error.location)) {
        formRef.current.updateInputsWithError({
          ...formRef.error
        });
        disableButton();
      }
    }, [formRef.error]);
    useEffect(() => {
      if (user && !user.verifiedData) {
        setIsOpen(true);
      }
    }, [user]);
    function disableButton() {
      setIsFormValid(false);
    }

    function enableButton() {
      setIsFormValid(true);
    }

    function handleSubmit(form: PersonalInformationEntity) {
      updatePersonalInformation({
        ...form,
        mobile: form.mobile.replace(/[()]/g, '').replace(/[\s]/g, '-'),
        phone: form.phone.replace(/[()]/g, '').replace(/[\s]/g, '-')
      }).then(() => {
        validateDataAction();
        setIsOpen(false);
      });
    }

    return (
      <div className="Modal-Container">

        <Modal show={isOpen} onHide={() => undefined}>
          <Modal.Header >
            <h5 className="modal-title">Actualización de datos</h5>
          </Modal.Header>
          <Modal.Body className="p-2">

            <Formsy
              onValidSubmit={handleSubmit}
              onValid={enableButton}
              onInvalid={disableButton}
              ref={formRef}
              className="flex flex-col p-16">

              <NumberFormatFormsy
                wrappedClass="mx-2 mb-4"
                className="form-control input-date"
                label="Teléfono"
                name="phone"
                value={(user && user.phone) || ''}
                type="text"
                icon={faPhoneAlt}
                validations={{
                  consecutiveNumber: true,
                  isNumberPhone: true
                }}
                validationErrors={{
                  isNumberPhone: 'No es valido',
                  consecutiveNumber: 'No colocar números consecutivos'
                }}
                required
                format="(###) ###-####"
                placeholder="(000) 000-0000"
              />
              <NumberFormatFormsy
                wrappedClass="mx-2 mb-4 input-date"
                className="form-control"
                label="Móvil"
                name="mobile"
                type="text"
                value={(user && user.mobile) || ''}
                icon={faMobile}
                validations={{
                  isNumberPhone: true,
                  consecutiveNumber: true
                }}
                validationErrors={{
                  isNumberPhone: 'No es valido',
                  consecutiveNumber: 'No colocar números consecutivos'
                }}
                required
                format="(###) ###-####"
                placeholder="(000) 000-0000"
              />

              <TextFieldFormsy
                wrappedClass="mx-2"
                className="form-control input-date"
                type="text"
                required
                label="Correo Personal"
                value={(user && user.personalEmail) || ''}
                name="personalEmail"
                icon={faEnvelope}
                validations={{
                  isEmail: true,
                  noEmailDomain: ['365.unphu.edu.do', 'unphu.edu.do']
                }}
                validationErrors={{
                  isEmail: 'Correo No valido',
                  noEmailDomain: 'No se admiten (@365.unphu.edu.do, @unphu.edu.do)'

                }}
              />

            </Formsy>

            <div className="footer-alert">
              <Button
                disabled={!isFormValid }
                className="btn-gmail"
                onClick={() => handleSubmit(formRef.current.getCurrentValues())}
                variant="outline-primary"
              >
                Enviar
                {loading && <Spinner

                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="spinner-loading ml-2"
                />}

              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

const personalInformationService = new BaseService('personalInformation');
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    updatePersonalInformation: personalInformationService.create,
    validateDataAction: Actions.validateDataAction
  }, dispatch);
};
const mapState2Props = (state: stateType) => {
  return {
    user: state.authenticationReducer.user,
    loading: state.entityReducer.personalInformation.create.loading,
    error: state.entityReducer.personalInformation.create.error
  };
};
export default withRouter(connect(mapState2Props, mapDispatchToProps)(ModalForm as any));
