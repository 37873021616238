import React from 'react';
import './ModalAdvice.scss';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimesCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';

export interface ModalProps{
  isOpen: boolean,
  title: string,
  subTitle: string,
  description: string | any,
  error: boolean,
  icons: 'faTimesCircle'| 'faCheck' | 'faExclamation';
  onClose: ()=> void
}

const ModalAdvice: React.FunctionComponent<ModalProps> =
  (props) => {
    const icon = () => {
      switch (props.icons) {
        case 'faCheck':
          return faCheck;
        case 'faExclamation':
          return faExclamation;
        case 'faTimesCircle':
          return faTimesCircle;
        default:
          return faCheck;
      }
    };
    return (
      <div className="Modal-Container">

        <Modal show={props.isOpen} onHide={() => undefined}>
          <Modal.Body>
            <div className={props.icons === 'faExclamation' ? 'container-alert-warning' : (!props.error ? 'container-alert-valid' : 'container-alert')}>
              <FontAwesomeIcon icon={icon()} className="icon-modal" />
              <h5>{props.title}</h5>
              <h2>{props.subTitle}</h2>
            </div>

            <div className="footer-alert">
              <p>
                {props.description}
              </p>
              {props.error && <a href="https://asistencia.unphu.edu.do/">asistencia.unphu.edu.do</a>}
              <Button className="btn-gmail" onClick={() => props.onClose()} variant="outline-primary">{props.error ? 'Cerrar' : 'Aceptar'}</Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  };

export default ModalAdvice;
