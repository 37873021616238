import React from 'react';
import './style.scss';
import { Accordion, Card, Button, ListGroup, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBill,
  faChevronDown, faTag
} from '@fortawesome/free-solid-svg-icons';
import Spinnercomponet from '../SpinnerComponet';
import BillsEntity from '../../shared/entities/BillsEntity';
import { currency, currencyEnum } from '../../shared/utills/currency';
import { get } from 'lodash';

interface PayListPros {
  data: BillsEntity
  onlyRead?: boolean;
  enrolmentService?: number[];
  onlyCheck?: boolean;
  payCurrent?: boolean;
  loading?: boolean
}
const PayList: React.FunctionComponent<PayListPros> =
  (props) => {
    const {
      data,
      onlyRead,
      payCurrent,
      loading,
      enrolmentService
    } = props;
    const PeriodsHelpers: any[] = [
      {
        id: 1,
        name: 'Enero - Abril'
      },
      {
        id: 2,
        name: 'Mayo - Agosto'
      },
      {
        id: 3,
        name: 'Septiembre - Diciembre'
      }
    ];
    return (
      <div className="container-list">
        {loading && <Spinnercomponet/>}

        {/* pack list */}
        {data && data.serviceCharges && data.serviceCharges
          .map((item, index) => {
            const availableToCheck = item.service.description === 'Saldo no vencido';
            const noChangeValue = enrolmentService && enrolmentService.indexOf(Number(item.service.id)) >= 0;
            return item.service.description !== 'Descuento' && (
              <Accordion key={Math.random()} >
                {/* card acoordion */}
                <Card>
                  <Card.Header>
                    {/* header title */}
                    {/* componet select */}

                    <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                      <input
                        disabled={true} // !(availableToCheck && !payCurrent) || (onlyRead)
                        type="checkbox"
                        checked={true}
                        className="mr-2"
                      />

                      <FontAwesomeIcon icon={faMoneyBill} className="icon" />

                      {item.period !== 0 ? item.period && <span className="ml-3 year-text">{PeriodsHelpers[item.period - 1].name} {item.year} | </span> : ''}
                      <span className="ml-3">{item.service.name || item.service.description}</span>
                      <b>{
                        (noChangeValue && currency(item.amountToBill || item.service.description, currencyEnum[data.suggestedAccount.currencyType.id])) ||
                        (!payCurrent && !availableToCheck
                          ? currency(item.amountToBill || item.amountToPay, currencyEnum[data.suggestedAccount.currencyType.id])
                          : currency(item.amountToBill || item.grossAmount, currencyEnum[data.suggestedAccount.currencyType.id]))
                      }</b><small className="ml-3 pending-small">{Number(item.amountPending) > 0 && `Pendiente: ${currency(item.amountPending || item.amountPending)}`}</small>
                      <span className="container-select">
                        <FontAwesomeIcon icon={faChevronDown} className="icon" />
                      </span>
                    </Accordion.Toggle>
                  </Card.Header>
                  {/* body accordion */}
                  <Accordion.Collapse eventKey={index.toString()}>

                    <Card.Body>
                      <div className="container-pay-list">

                        <div className="amount-container">
                          {Number(item.amountDiscount) > 0 &&
                          <div className="container-badge mb-3">
                            <Badge className="mb-3" variant="success">
                              <FontAwesomeIcon icon={faTag} className="icon" /> Descuento aplicado </Badge>{' '}
                          </div>
                          }

                          {/* <h5><FontAwesomeIcon icon={faFileInvoiceDollar} className="icon"/> Información</h5> */}

                          <ListGroup variant="flush">
                            {Number(item.quantity) > 0 && <ListGroup.Item> <b>Cantidad:</b> { item.quantity}</ListGroup.Item>}
                            <ListGroup.Item> <b>Beca:</b> {currency(item.scholarshipAmount || item.scholarshipAmount, currencyEnum[data.suggestedAccount.currencyType.id])}</ListGroup.Item>
                            <ListGroup.Item> <b>Monto:</b> {currency(item.grossAmount || item.grossAmount, currencyEnum[data.suggestedAccount.currencyType.id])}</ListGroup.Item>
                            <ListGroup.Item> <b>Descuento:</b> {currency(item.amountDiscount || item.amountDiscount, currencyEnum[data.suggestedAccount.currencyType.id])} </ListGroup.Item>
                            <ListGroup.Item> <b>Por pagar:</b> {currency(item.amountToBill || item.amountToPay, currencyEnum[data.suggestedAccount.currencyType.id])} </ListGroup.Item>
                            <ListGroup.Item> <b>Pendiente:</b>  {currency(item.amountPending || item.amountPending, currencyEnum[data.suggestedAccount.currencyType.id])} </ListGroup.Item>
                          </ListGroup>

                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>);
          }
          )

        }

      </div>

    );
  };

export default PayList;
