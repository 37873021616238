import * as jwt from 'jsonwebtoken';
import { CryptoService } from './crypto/CryptoService';

/**
 * This class contain the logic for decode access token and refresh token
 */
export class TokenService {
  static async decode(token: string, secretKey: string): Promise <any> {
    return new Promise((resolve, reject) => {
      try {
        const crypto = new CryptoService(process.env.REACT_APP_CRYPTO_KEY || '');
        const dataStored = jwt.verify(token, crypto.decrypt(secretKey) as string) as any;

        const data: any = {
          token,
          type: dataStored.type,
          user: dataStored.user,
          service: dataStored.service

        };
        localStorage.setItem('token', token);
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
