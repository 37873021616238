import * as React from 'react';
import UnphuLogo from '../../assets/Logo-unphu-pago.svg';
import { faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import './styles.scss';
import { bindActionCreators, Dispatch } from 'redux';
import * as Actions from '../../store/actions';
import { stateType } from '../../store';
import { connect } from 'react-redux';
import { authenticationInitialState } from '../../store/reducers/AuthenticationReducer';
import { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Formsy from 'formsy-react';
import TextFieldFormsy from '../formsy/TextFieldFormsy';
import { useRef } from 'react';
import PasswordFieldFormsy from '../formsy/PasswordFieldFormsy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export interface ILoginFormProps extends RouteComponentProps {
  authentication: authenticationInitialState
  loginAction: (username: string, password: string) => void
  loading:boolean
  error:any
}

const LoginForm: React.FunctionComponent<ILoginFormProps> =
  (props) => {
    const { authentication } = props;
    const [isUnlockedPassword, setIsUnlockedPassword] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState(false);
    const formRef: any = useRef(null);

    useEffect(() => {
      if (formRef.error && (formRef.error.name || formRef.error.code || formRef.error.location)) {
        formRef.current.updateInputsWithError({
          ...formRef.error
        });
        disableButton();
      }
    }, [formRef.error]);

    function disableButton() {
      setIsFormValid(false);
    }

    function enableButton() {
      setIsFormValid(true);
    }

    function handleSubmit(data: {username: string; password:string}) {
      props.loginAction(data.username, data.password);
    }

    useEffect(() => {
      if (authentication.token) {
        localStorage.setItem('token', authentication.token);
        window.location.href = '/pagos';
      }
    }, [authentication, props.history]);

    return (
      <div className="login-form">
        <img src={UnphuLogo} alt="UNPHU Logo"/>
        { props.error && <div className="d-flex container-alert justify-content-center">
          <span>
            <FontAwesomeIcon icon={faTimes} className="icon mr-2" />
             El usuario o contraseña no es valído
          </span>
        </div>}
        <Formsy
          onValidSubmit={handleSubmit}
          onValid={enableButton}
          onInvalid={disableButton}
          ref={formRef}
          className="flex flex-col p-16"
        >

          <TextFieldFormsy
            wrappedClass="col col-12 p-40 pb-0"
            type="text"
            name="username"
            required
            className="form-control"
            placeholder="Usuario"
          />

          <PasswordFieldFormsy
            icon={faUser}
            name="password"
            isUnlockedPassword={isUnlockedPassword}
            setIsUnlockedPassword={setIsUnlockedPassword}
            required
            className="form-control"
            placeholder="Contraseña"
          />
        </Formsy>

        <button className="btn btn-lg"
          disabled={!isFormValid}
          onClick={() => handleSubmit(formRef.current.getCurrentValues())}>
            Iniciar sesión
          {props.loading && <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="spinner-loading"
          />}
          <span className="sr-only">Loading...</span>
        </button>

        <div className="login-hint">
          <p>El usuario y contraseña</p>
          <p>
              es el mismo que se usa en{' '}
            <a href="http://akademia.unphu.edu.do/SitePages/Inicio.aspx">
                AKADEMIA
            </a>
          </p>
        </div>
      </div>
    );
  };

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    loginAction: Actions.loginAction
  }, dispatch);
};
const mapState2Props = (state: stateType) => {
  return {
    authentication: state.authenticationReducer,
    error: state.authenticationReducer.error,
    loading: state.authenticationReducer.loading
  };
};

export default withRouter(connect(mapState2Props, mapDispatchToProps)(LoginForm));
